<template>
  <div>
    <BAlert
      :show="totalCard >= maxCard && isUnlimitedCard"
      variant="success"
      class="p-1"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div class="flex items-start gap-3 mb-[10px]">
          <img
            class="h-[20px]"
            src="https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg"
            alt="check-list"
          >
          <div>
            <h4 class="text-[#626262] font-semibold text-[16px] m-0">
              Berhasil mendapatkan kuota kartu unlimited!
            </h4>
            <div class="text-[#626262] text-[14px] mt-[12px]">
              Terus gunakan kartumu dan lakukan spending sebanyak-banyaknya
            </div>
          </div>
        </div>
        <div class="w-[20px]">
          &nbsp;
        </div>
      </div>
    </BAlert>
    <BAlert
      v-if="isCheckKyc"
      show
      variant="warning"
      class="p-2"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div>
          <div class="flex items-center gap-3 mb-[10px]">
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
              alt="Komerce"
            >
            <h4 class="text-black font-semibold m-0">
              {{ titleCheckKyc }}
            </h4>
          </div>
          <BCardText class="text-[#333333]">
            {{ messageCheckKyc }}
          </BCardText>
        </div>
        <BButton
          v-if="
            profile.verification_ktp_status === 0 ||
              profile.verification_ktp_status === 3
          "
          size="sm"
          variant="outline-warning"
          @click="$router.push('/verification-ktp')"
        >
          {{ buttonKycBanner }}
        </BButton>
      </div>
    </BAlert>
    <BAlert
      v-if="warningMessage !== null"
      show
      variant="warning"
      class="p-2"
      dismissible
    >
      <h4 class="text-black font-semibold">
        Informasi Pembuatan Kartu
      </h4>
      <template slot="dismiss">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
          alt="Komerce"
          width="20"
          class="cursor-pointer"
        >
      </template>
      <BCardText class="text-[#333333]">
        {{ warningMessage }}
      </BCardText>
    </BAlert>
    <BCard>
      <BRow class="items-center justify-between pl-[15px] pr-[15px]">
        <div class="flex gap-[12px] items-center">
          <div class="text-black font-semibold m-0 text-2xl">
            Kartu Debit
          </div>
          <div
            class="text-[16px] flex items-center border-[1px] border-[#E2E2E2] rounded-[8px] px-[8px] py-[4px] py px-[12px] py-[8px]"
          >
            <span class="text-[#34A770]">{{ filtered_total }}/</span>
            <span v-if="totalCard >= maxCard && isUnlimitedCard !== null">
              <img
                alt="infinity card"
                class="h-[14px]"
                src="https://storage.googleapis.com/komerce/assets/elements/badge-infinite.png"
              ></span>

            <span
              v-show="isUnlimitedCard !== null"
              v-else
            >{{ maxCard }}</span>
          </div>
        </div>
        <BButton
          size="sm"
          :variant="isCheckKyc ? 'secondary' : 'primary'"
          style="display: flex; align-items: center; justify-content: space-between; padding: 5px 10px;"
          :disabled="loading.pin || loading.balance || isCheckKyc"
          @click="checkPin('button-add')"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/card-add.svg"
            alt="Komerce"
            width="20"
          >
          <div class="d-none d-md-block font-semibold ml-[5px]">
            Tambah Kartu
          </div>
          <BSpinner
            v-if="loading.pin"
            variant="light"
            small
            class="ml-1"
          />
        </BButton>
      </BRow>
      <div
        class="flex justify-between items-end md:items-center mt-[16px]  gap-[10px] mb-[15px] md:mb-0"
      >
        <div class="flex items-end gap-[10px]">
          <div
            class="mt-[12px] w-[300px] sm:flex gap-[10px] sm:items-center w-full sm:w-auto"
          >
            <div class="flex gap-[2px] items-center relative">
              <img
                class="absolute h-[20px] ml-[7px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                alt="search"
              >
              <BFormInput
                v-model="search_card"
                debounce="600"
                placeholder="Cari kartu komcards"
                class="pl-3 pr-2"
                style="height: 37px !important;"
                @keyup="handleEnterSearch"
              />
              <img
                v-if="search_card !== null"
                class="h-[12px] right-0 absolute mr-[10px] cursor-pointer"
                src="https://storage.googleapis.com/komerce/assets/LP-Komcard/close-circle.svg"
                alt="close"
                @click="resetSearch"
              >
            </div>
          </div>
          <VSelect
            ref="tapi"
            v-model="status_card"
            :options="optionStatus"
            :reduce="option => option.value"
            :clearable="false"
            class="w-[200px]  d-none d-md-block"
          />
        </div>
        <div
          class="flex border-[1px] d-none d-md-block rounded-[8px] overflow-hidden border-[#E2E2E2]"
        >
          <button
            :class="
              activeViewCard === 'grid'
                ? 'bg-[#F95031] text-white cursor-pointer'
                : 'bg-white text-[#F95031] cursor-auto'
            "
            class="p-[10px] border-r-[1px] border-[#E2E2E2]"
            @click="activeViewCard !== 'grid' && changeView('grid')"
          >
            <span class="k-category" />
          </button>
          <button
            :class="
              activeViewCard === 'list'
                ? 'bg-[#F95031] text-white cursor-pointer'
                : 'bg-white text-[#F95031] cursor-auto'
            "
            class="p-[10px]"
            @click="activeViewCard !== 'list' && changeView('list')"
          >
            <span class="k-menu" />
          </button>
        </div>
        <div class="d-md-none flex items-center gap-[6px]">
          <div class="h-[35px] w-[40px] relative">
            <button
              class="h-full w-full border-[1px] rounded-[8px] flex items-center justify-center  border-[#F95031] bg-white text-black cursor-pointer"
              @click="isActivePopover = !isActivePopover"
            >
              <span class="k-filter text-[#F95031]" />
            </button>
            <div
              v-show="isActivePopover"
              class="w-[200px] bg-white mt-[20px] overflow-hidden rounded-[8px] border-[1px] border-[#E2E2E2] z-[999] absolute right-1 top-6"
            >
              <template v-for="(option, index) in optionStatus">
                <div
                  :key="index"
                  :class="
                    status_card === option.value
                      ? 'bg-[#F8F8F8] text-[#c2c2c2]'
                      : 'text-[#828282]'
                  "
                  class="px-[12px] py-[8px]"
                  @click="status_card = option.value"
                >
                  {{ option.label }}
                </div>
              </template>
            </div>
          </div>
          <div class="h-[35px] w-[40px]">
            <button
              class="h-full  w-full border-[1px] rounded-[8px] flex items-center justify-center border-[#E2E2E2] bg-white text-black cursor-pointer"
              @click="
                activeViewCard === 'grid'
                  ? changeView('list')
                  : changeView('grid')
              "
            >
              <span
                :class="activeViewCard !== 'grid' ? 'k-menu' : 'k-category'"
              />
            </button>
          </div>
        </div>
      </div>
      <!-- START : CARD LIST -->
      <div>
        <div
          v-if="
            dataCard.length === 0 && !loading.card && activeViewCard === 'grid'
          "
          body-class="card-dashed"
          class="mb-[10px]"
        >
          <BOverlay
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <div
              class="flex flex-column mt-[12px]  justify-center items-center w-[290px]  sm:w-[350px] h-[250px] p-[20px] border-[1px] border-[#E2E2E2] rounded-[8px] border-dashed"
              :class="totalCard === 0 && 'hover:bg-[#F8F8F8] cursor-pointer'"
              @click="totalCard === 0 && checkPin('banner-empty-card')"
            >
              <img
                :src="
                  totalCard > 0
                    ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Ilustrasi-addcards.svg'
                    : 'https://storage.googleapis.com/komerce/assets/svg/empty-card.svg'
                "
                alt="Komerce"
                class="m-auto"
              >

              <div class="mt-2 text-[#626262]">
                <span v-if="totalCard > 0">Tidak Ada Kartu</span>
                <div
                  v-else
                  class="text-center"
                >
                  Kamu belum memiliki kartu. Yuk, buat kartu pertama kamu!
                </div>
              </div>
            </div>
          </BOverlay>
        </div>
      </div>
      <div class="sm:mt-[15px] lg:mt-0">
        <BOverlay
          v-if="activeViewCard === 'grid'"
          spinner-variant="primary"
          :show="loading.card"
        >
          <!-- START : GRID -->
          <VueSlickCarousel
            v-if="dataCard.length > 0"
            ref="carousel"
            :key="carouselKey"
            unslick
            :initial-slide="initialSlide"
            v-bind="settings"
            @afterChange="handleBeforeChange"
          >
            <NewCC
              v-for="(data, key) in dataCard"
              :id="data.id"
              :key="key"
              class="px-[5px]"
              :balance="data.balance"
              :card_number="data.card_number"
              :label="data.label"
              :status="data.status"
              :handle-action-topup="handleTopup"
              :handle-detail="handleDetail"
              @handleTransfer="handleTransfer"
            />
          </VueSlickCarousel>
          <!-- END : GRID -->
        </BOverlay>
        <BOverlay
          v-else
          spinner-variant="primary"
          :show="loading.card"
        >
          <!-- START : LIST -->
          <BTable
            :items="dataCard"
            :fields="renderListView(fieldsListView)"
            class="mt-[15px] table-card"
            show-empty
            :thead-class="windowWidth < 480 ? 'd-none' : ''"
            sticky-header="500px"
            @scroll.native="handleScroll"
          >
            <template #empty>
              <div
                v-if="
                  dataCard.length === 0 &&
                    totalCard === 0 &&
                    !loading.card &&
                    activeViewCard === 'list'
                "
                class="flex flex-column mt-[12px] bg-[#F8F8F8] justify-center items-center hover:bg-[#F8F8F8] cursor-pointer"
                @click="totalCard === 0 && checkPin('banner-empty-card')"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/empty-card.svg"
                  alt="Komerce"
                  class="m-auto"
                >
                <div class="mt-2 text-[#626262]">
                  <div class="text-center">
                    Kamu belum memiliki kartu. Yuk, buat kartu pertama kamu!
                  </div>
                </div>
              </div>
              <div
                v-else
                class="min-h-[50px] flex justify-center mt-[12px] w-full "
              >
                <div>
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Ilustrasi-addcards.svg"
                    alt="Komerce"
                    class="m-auto"
                  >
                  <div class="mt-1 mb-2 text-[#626262]">
                    <div class="text-center">
                      Tidak Ada Kartu
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(card_name)="data">
              <div class="text-[14px] gap-[6px] flex items-start">
                <span class="k-card text-[#C2C2C2] text-[20px]" />
                <div>
                  <div class="flex gap-[4px]">
                    <span class="text-[#626262] text-nowrap">
                      {{ data.item.label }}
                    </span>
                    <div class="text-[#626262]">
                      {{
                        `***${
                          data.item.card_number
                            ? data.item.card_number.slice(-4)
                            : ''
                        }`
                      }}
                    </div>
                  </div>
                  <div :class="windowWidth > 480 && 'd-none'">
                    <div v-if="data.item.balance">
                      {{ IDR(data.item.balance, 2, 2) }}
                    </div>
                    <div
                      v-if="data.item.balance === undefined"
                      class="color"
                    >
                      <span class="h-[10px] w-[100px]" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(balance)="data">
              <div v-if="data.item.balance">
                {{ IDR(data.item.balance, 2, 2) }}
              </div>
              <div
                v-if="data.item.balance === undefined"
                class="color"
              >
                <span class="h-[10px] w-[100px]" />
              </div>
            </template>
            <template #cell(action)="data">
              <button
                :id="'popover-action' + data.item.id"
                class="p-[6px] rounded-[8px] bg-[#F8F8F8]"
                :class="windowWidth > 480 && 'd-none'"
                @click="
                  handleClickPopoverOnTable('popover-action' + data.item.id)
                "
              >
                <span class="k-more text-[#333333]" />
              </button>
              <BPopover
                triggers="click"
                placement="bottomleft"
                :target="'popover-action' + data.item.id"
              >
                <button
                  class="flex gap-1 items-center"
                  :class="
                    renderActionList('topup', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  @click="
                    renderActionList('topup', data.item.status) &&
                      handleTopup(data.item)
                  "
                >
                  <span class="k-send-square text-[16px]" />
                  <span class="text-[14px]">Topup </span>
                </button>
                <button
                  class="flex gap-1 mt-[8px] items-center"
                  :class="
                    renderActionList('transfer', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  @click="
                    renderActionList('transfer', data.item.status) &&
                      handleTransferWithAlert(data.item)
                  "
                >
                  <span class="k-convert-card  text-[16px]" />
                  <span class="text-[px]">Transfer Saldo</span>
                </button>
                <button
                  class="flex gap-1 mt-[8px] items-center"
                  :class="
                    renderActionList('detail', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  @click="
                    renderActionList('detail', data.item.status) &&
                      handleDetail(data.item)
                  "
                >
                  <span class="k-card text-[16px]" />
                  <span class="text-[14px]">Detail </span>
                </button>
              </BPopover>
              <div
                class="flex justify-center w-full gap-[10px]"
                :class="windowWidth < 480 && 'd-none'"
              >
                <div
                  v-b-tooltip.hover
                  title="Topup Komcards"
                  :class="
                    renderActionList('topup', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  class="bg-[#F8F8F8] rounded-[8px] p-[5px] justify-center items-center"
                  @click="
                    renderActionList('transfer', data.item.status) &&
                      handleTopup(data.item)
                  "
                >
                  <span class="k-send-square text-[24px]" />
                </div>

                <div
                  v-b-tooltip.hover
                  title="Transfer Saldo"
                  class="bg-[#F8F8F8] rounded-[8px] p-[5px] flex justify-center items-center"
                  :class="
                    renderActionList('transfer', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  @click="
                    renderActionList('transfer', data.item.status) &&
                      handleTransferWithAlert(data.item)
                  "
                >
                  <span class="k-convert-card text-[24px]" />
                </div>
                <div
                  v-b-tooltip.hover
                  title="Detail"
                  class="bg-[#F8F8F8] rounded-[8px] p-[5px] justify-center items-center"
                  :class="
                    renderActionList('detail', data.item.status)
                      ? 'text-[#333333] cursor-pointer hover:bg-[#FFECE9]'
                      : 'text-[#c2c2c2]'
                  "
                  @click="
                    renderActionList('detail', data.item.status) &&
                      handleDetail(data.item)
                  "
                >
                  <span class="k-card text-[24px]" />
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <span
                v-if="data.item.status === 'active'"
                class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center"
              >
                <div class="bg-[#34A770] h-[8px] w-[8px] rounded-full" />
                <div>Aktif</div>
              </span>
              <span
                v-if="data.item.status === 'inactive'"
                class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center"
              >
                <div class="bg-[#E31A1A] h-[8px] w-[8px] rounded-full" />
                <div>Tidak Aktif</div>
              </span>
              <span
                v-if="data.item.status === 'pending'"
                class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center"
              >
                <div class="bg-[#c2c2c2] h-[8px] w-[8px] rounded-full" />
                <div>Pending</div>
              </span>
            </template>
          </BTable>
          <!-- END : LIST -->
        </BOverlay>
      </div>
      <!-- END : CARD LIST  -->

      <div
        v-if="activeViewCard === 'grid'"
        class="flex d-flex justify-center gap-[5px] mb-[20px]"
      >
        <button
          :disabled="renderButtonNext || loading.card"
          :class="renderButtonNext && !loading.card && 'bg-[#F4F4F4]'"
          class="flex focus:ring-0 focus:outline-none focus:ring-offset-0 outline-none justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
          @click="prevCarousel"
        >
          <img
            class="h-1/2"
            src="https://storage.googleapis.com/komerce/assets/illustration/arrow-left.svg"
            alt="left"
          >
        </button>
        <button
          :disabled="renderButtonNext || loading.card"
          :class="renderButtonNext && !loading.card && 'bg-[#F4F4F4]'"
          class="flex focus:ring-0 focus:ring-offset-0 focus:outline-none justify-center items-center h-[32px] w-[32px] border-[#E2E2E2] border-[1px] rounded-[6px]"
          @click="nextCarousel"
        >
          <img
            class="h-1/2"
            src="https://storage.googleapis.com/komerce/assets/illustration/arrow-right.svg"
            alt="left"
          >
        </button>
      </div>
      <hr
        class="border-[1px] mt-[20px] lg:mt-0 border-[#E2E2E2] border-dashed"
      >
      <!-- ketika kurang dari x dan active maka grid-cols-1 -->
      <div
        :class="[
          isActiveGamification
            ? 'grid-content-speding-2'
            : 'grid-content-speding-1',
          !isVerticalMenuCollapsed ? 'grid-content-speding-force' : '',
        ]"
        class="mt-[30px] gap-[16px] lg:mt-[40px]"
      >
        <div class="h-full">
          <SpendingCategoryVue class="h-full" />
        </div>
        <Gamification
          v-if="isActiveGamification"
          :data-gamification="dataGamification"
          :max-spending-gamification="maxSpendingGamification"
          :leveling-gamification="levelingGamification"
          :spending-gamification="spendingGamification"
          :render-banner="renderBannerGamification"
          :render-progress="renderProgressGamification"
          @claim-gamification="handleClaimGamification"
        />
      </div>
      <hr
        class="border-[1px] mt-[20px] lg:mt-[40px] border-[#E2E2E2] border-dashed"
      >
      <BRow
        class="pl-[15px] pr-[15px] mt-[20px] lg:mt-[40px]  items-center justify-between"
      >
        <div class="text-black font-semibold m-0 text-2xl">
          Mutasi
        </div>
        <div class="flex items-center flex-row">
          <BButton
            style="padding: 5px 1rem"
            variant="outline-primary"
            size="sm"
            @click="$bvModal.show('download-mutation-transaction-komcards')"
          >
            <div class="ml-[10px] mr-[10px] flex items-center">
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
              />
              <span class="ml-[4px]">Download Mutasi</span>
            </div>
          </BButton>
          <div class="ml-1">
            <BButton
              id="filter"
              size="sm"
              variant="primary"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                alt="Komerce"
                width="20"
              >
            </BButton>
            <BPopover
              target="filter"
              triggers="click"
              placement="bottomleft"
            >
              <label>Tanggal</label>
              <BRow>
                <BCol md="6">
                  <FlatPickr
                    v-model="startDate"
                    class="form-control"
                    placeholder="Mulai Dari"
                    :config="{
                      mode: 'single',
                      altInput: true,
                      altFormat: 'j/n/Y',
                      dateFormat: 'Y-m-d',
                    }"
                    @input="setStartDate"
                  />
                </BCol>
                <BCol md="6">
                  <FlatPickr
                    v-model="endDate"
                    class="form-control"
                    placeholder="Sampai Dengan"
                    :config="{
                      mode: 'single',
                      altInput: true,
                      altFormat: 'j/n/Y',
                      dateFormat: 'Y-m-d',
                      minDate: startDate,
                    }"
                    @input="setEndDate"
                  />
                </BCol>
              </BRow>
              <label class="mt-1">Jenis Transaksi</label>
              <VSelect
                v-model="transactionType"
                :options="optionsTransactionType"
                :reduce="option => option.value"
                label="name"
                @input="setTransactionType"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <label class="mt-1">Nama Kartu</label>
              <VSelect
                v-model="cardId"
                :options="optionsCardId"
                :reduce="option => option.value"
                label="name"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <BRow class="mx-auto mt-2">
                <BButton
                  variant="outline-primary"
                  class="mr-1"
                  @click.prevent="resetFilter()"
                >
                  Reset
                </BButton>
                <BButton
                  variant="primary"
                  @click.prevent="getListMutation()"
                >
                  Terapkan
                </BButton>
              </BRow>
            </BPopover>
          </div>
        </div>
      </BRow>
      <Mutation
        :scroll="'scroll'"
        :loading="loading.mutation"
        :items="listMutation"
        :fields="fields"
        :status-class="statusClass"
        :nominal-class="nominalClass"
      />

      <ModalCreatePin />
      <ModalInfoBalance
        :balance="balance"
        :min-saldo-kompay="minSaldoKompay"
      />
      <ModalTopup
        :card-data="cardData"
        :get-cards="getListCard"
        :get-mutation="getListMutation"
        :balance-data="balanceData"
      />
      <ModalDownloadMutasi :list-cards="allDataCard" />
      <ModalTransfer
        :id="idModalTransfer"
        :info-balance="balanceDetail"
      />
      <ModalLimitQuota />
      <ModalGamification @refresh="getGamificationData" />
    </BCard>
  </div>
</template>

<script>
import { FULL_DATE_TIME, YEAR_MONTH_DAY } from '@/libs/formatDate'
import size from 'lodash/size'
import moment from 'moment'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import VueSlickCarousel from 'vue-slick-carousel'
import { IDR } from '@/libs/currency'
import useVerticalNavMenu from '@/@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import {
  columnConfig,
  settings,
  optionsTransactionType,
  listViewConfig,
} from './config'
import {
  Mutation,
  NewCC,
  ModalCreatePin,
  ModalInfoBalance,
  ModalTransfer,
  ModalTopup,
  ModalDownloadMutasi,
  ModalGamification,
  Gamification,
} from './components'
import ModalLimitQuota from './components/ModalLimitQuota.vue'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'

// import 'vue-select/dist/vue-select.css'
import SpendingCategoryVue from './components/SpendingCategory.vue'

export default {
  components: {
    ModalLimitQuota,
    Mutation,
    Gamification,
    ModalCreatePin,
    ModalInfoBalance,
    ModalTopup,
    ModalGamification,
    FlatPickr,
    VSelect,
    NewCC,
    VueSlickCarousel,
    SpendingCategoryVue,
    ModalDownloadMutasi,
    ModalTransfer,
  },
  data() {
    return {
      IDR,
      FULL_DATE_TIME,
      YEAR_MONTH_DAY,

      // START : LIST VIEW
      fieldsListView: listViewConfig,
      activeViewCard: 'grid',

      // END : LIST VIEW

      // START : carousel state
      stateCarousel: 0,
      firstRenderCards: true,
      initialSlide: 0,
      jumlahSlide: 3, // dynamic by viewport
      settings,
      windowWidth: 0,
      carouselKey: 0,
      onSearch: false,
      // END : carousel state

      // START : params data cards
      dataCard: [],
      isUnlimitedCard: null,
      limit_card: 10,
      offset_card: 0,
      search_card: null,
      status_card: null,
      // END : params data cards

      idModalTransfer: null,
      isActivePopover: false,
      scrollY: 0, // Track scroll position
      optionStatus: [
        {
          label: 'Semua Status',
          value: null,
        },
        {
          label: 'Aktif',
          value: 'active',
        },
        {
          label: 'Tidak Aktif',
          value: 'inactive',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
      ],
      status: null,
      fields: columnConfig,
      listMutation: [],
      showCardDetails: false,
      activeCard: null,
      balanceDetail: {},
      loading: {
        card: false,
        mutation: false,
        balanceDetailForTransfer: false,
        pin: false,
        balance: false,
        getBalanceMonit: false,
      },
      size,
      idPartner: this.$store.state.auth.userData.id,
      balance: null,
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      limit: 10,
      offset: 0,
      lastData: false,
      totalCard: null,
      lastDataCard: false,
      transactionType: '',
      optionsTransactionType,
      cardId: '',
      optionsCardId: [],
      allDataCard: [],
      selectedTransactions: [],
      filtered_total: null,
      maxCard: 0,
      minCard: 4,
      maxCardMessage: '',
      minCardMessage:
        'Kamu telah membuat 5 kartu komcards. Biaya pembuatan kartu berikutnya adalah Rp. 25.000/kartu dengan saldo awal minimal Rp. 20.000. Periksa saldo kompaymu sebelum melanjutkan proses selanjutnya!',
      warningMessage: null,
      minTopUp: 45000,
      minSaldo: 20000,
      minSaldoKompay: null,
      cardData: {},
      showModalTopup: false,
      balanceData: null,
      profile: {},
      nextItem: false,

      // START : Gamification
      dataGamification: null,
      isActiveGamification: null,
      bannerGamification: 'active',
      spendingGamification: null,
      maxSpendingGamification: 400000000,
      levelingGamification: [
        {
          title: 'Level 1',
          labelCurrentcy: 'Rp100Juta',
          valueMinimum: 100000000,
        },
        {
          title: 'Level 2',
          labelCurrentcy: 'Rp200Juta',
          valueMinimum: 200000000,
        },
        {
          title: 'Level 3',
          labelCurrentcy: 'Rp300Juta',
          valueMinimum: 300000000,
        },
        {
          title: 'Level 4',
          labelCurrentcy: 'Rp400Juta',
          valueMinimum: 400000000,
        },
      ],
      // END
    }
  },
  setup(props) {
    const { isVerticalMenuCollapsed } = useVerticalNavMenu(props.item)

    return {
      isVerticalMenuCollapsed,
    }
  },
  computed: {
    renderButtonNext() {
      if (this.windowWidth <= 480 && this.dataCard.length <= 1) {
        return true
      }

      if (this.windowWidth >= 481 && this.windowWidth <= 820 && this.dataCard.length <= 2) {
        return true
      }

      if (this.windowWidth >= 821 && this.dataCard.length <= 3) {
        return true
      }

      // return true means true for disable button
      return false
    },
    renderBannerGamification() {
      if (
        this.dataGamification.achieved_at === null
        && this.dataGamification.expired_at === null
      ) {
        return 'pending'
      }

      if (new Date().getTime() > new Date(this.dataGamification.expired_at).getTime()) {
        return 'expired'
      }

      if (new Date().getTime() < new Date(this.dataGamification.expired_at).getTime()) {
        return 'active'
      }

      return 'pending'
    },

    renderProgressGamification() {
      if (
        this.dataGamification.achieved_at === null
        && this.dataGamification.expired_at === null
      ) {
        return 'active'
      }

      return 'inactive'
    },
    setStartDate() {
      return value => {
        this.startDate = value
      }
    },
    setEndDate() {
      return value => {
        this.endDate = value
      }
    },
    setTransactionType() {
      return value => {
        this.transactionType = value
      }
    },
    statusClass() {
      return status => {
        if (status === 'success') {
          return 'text-[#34A770]'
        }
        if (status === 'failed') {
          return 'text-[#E31A1A]'
        }
        if (status === 'pending') {
          return 'text-[#828282]'
        }
        return ''
      }
    },
    nominalClass() {
      return type => {
        if (type === 'credit') {
          return 'text-[#34A770]'
        }
        if (type === 'debit') {
          return 'text-[#E31A1A]'
        }
        if (type === 'cashback') {
          return 'text-[#CC9212]'
        }
        return ''
      }
    },
    resetFilter() {
      return () => {
        this.startDate = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
        this.transactionType = ''
        this.cardId = ''
      }
    },

    isCheckKyc() {
      if (this.dataCard.length >= 1) {
        if (
          this.profile.verification_ktp_status === 0
          || this.profile.verification_ktp_status === 1
          || this.profile.verification_ktp_status === 3
        ) {
          return true
        }
      }
      return false
    },
    titleCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi KTP'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Verifikasi KTP dalam proses persetujuan'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi KTP ditolak'
      }
      return null
    },
    messageCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Setelah verifikasi berhasil, kamu juga bisa membuat kartu Komcards lagi'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Maaf kamu belum bisa membuat kartu baru karena sedang dalam proses persetujuan admin'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Pengajuan verifikasi KTP ditolak, silahkan ajukan verifikasi ulang untuk bisa membuat kartu baru'
      }
      return null
    },
    buttonKycBanner() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi Sekarang'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi Ulang'
      }
      return null
    },
  },
  watch: {
    scrollY: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.isActivePopover = false
          this.$root.$emit('bv::hide::popover')
        }
      },
    },
    status_card: {
      handler(newValue, oldValue) {
        this.stateCarousel = 0
        this.initialSlide = 0
        this.offset_card = 0
        this.carouselKey = 0
        this.lastDataCard = false
        this.firstRenderCards = true

        this.getListCard()
      },
    },
    dataCard: {
      handler(newValue, oldValue) {
        const arrayOfIdNew = newValue.map(e => e.id)
        const arrayOfIdOld = oldValue.map(e => e.id)

        const resultfilter = arrayOfIdNew.filter(
          item => !arrayOfIdOld.includes(item),
        )

        // handle jika user langsung ke kartu terakhir
        const resultfilterNull = newValue
          .filter(e => e.balance === undefined || e.balance === null)
          .map(e => e.id)
        if (this.dataCard.length > 0) {
          if (
            newValue.map(e => e.id).sort() !== oldValue.map(e => e.id).sort()
            && newValue.length > 0
            && resultfilterNull.length > 0
          ) {
            const combinedUnique = [
              ...new Set([...resultfilter, ...resultfilterNull]),
            ]

            this.getBalanceMonit(combinedUnique)
          }
          this.forceRerender()
        }
      },
      deep: true,
    },
    stateCarousel(newValue, oldValue) {
      // if previous state = 0 (first render) and current state = 1 (next state)
      if (
        newValue + 1 === this.dataCard.length
        && this.dataCard.length >= this.limit_card
        && this.filtered_total > this.limit_card
      ) {
        this.getNextDataCards('last')
        this.lastDataCard = true
      }

      if (newValue + this.jumlahSlide === this.dataCard.length && newValue > oldValue && this.filtered_total > this.limit_card) {
        this.getNextDataCards('next')
      }
    },
  },
  created() {
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY
          >= document.getElementById('scroll').offsetHeight
        && !this.loading.mutation
      ) {
        this.getNextDataMutation()
      }
    }
  },
  mounted() {
    this.activeViewCard = this.$store.state.komcards.viewList
    this.windowWidth = window.innerWidth
    // Optionally, add a resize event listener for dynamic updates
    window.addEventListener('resize', this.handleResize)

    window.addEventListener('scroll', this.handleScrollBody)
    this.checkUnlimitedCard()
    this.getListCard()
    this.getListMutation()
    this.getProfile()
    this.getAllDataCard()
    this.getGamificationData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScrollBody) // Clean up event listener
  },
  methods: {
    handleScrollBody() {
      this.scrollY = window.scrollY || document.documentElement.scrollTop // Get scroll position from window
    },
    handleClickPopoverOnTable(id) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('bv::show::popover', id)
    },
    renderListView(data) {
      if (this.windowWidth < 480) {
        return data.filter(e => e.key !== 'balance')
      }

      return data
    },
    renderActionList(action, status) {
      this.$root.$emit('bv::hide::popover')

      if (action === 'topup') {
        if (status === 'active') {
          return true
        }

        if (status === 'inactive') {
          return false
        }

        if (status === 'pending') {
          return false
        }
      } else if (action === 'transfer') {
        if (status === 'active') {
          return true
        }

        if (status === 'inactive') {
          return false
        }

        if (status === 'pending') {
          return false
        }
      } else {
        if (status === 'active') {
          return true
        }

        if (status === 'inactive') {
          return true
        }

        if (status === 'pending') {
          return false
        }
      }
      return false
    },
    // START: Method card change (transfer, Topup, Detail)
    handleDetail(card) {
      if (card.status === 'pending') {
        return null
      }
      return this.$router.push(`/komcards/${card.id}`)
    },
    handleTopup(card) {
      if (card.status === 'pending' || card.status === 'inactive') {
        if (card.status === 'inactive') {
          this.$toast_error({
            message:
              'Kartu anda tidak aktif, silahkan aktifkan terlebih dahulu',
          })
        }
        return
      }

      this.cardData = card
      this.showModalTopup = true
      this.getBalance()
    },
    handleTransfer(id) {
      this.balanceDetailForTransfer(id)
      this.idModalTransfer = parseInt(id, 10)
      this.$bvModal.show('modal-transfer')
    },
    handleTransferWithAlert(card) {
      if (card.status === 'pending' || card.status === 'inactive') {
        if (card.status === 'inactive') {
          this.$toast_error({
            message:
              'Kartu anda tidak aktif, silahkan aktifkan terlebih dahulu',
          })
        }

        return
      }
      this.handleTransfer(card.id)
    },
    // END: Method card change (transfer, Topup, Detail)

    // START : GAMIFICATION
    handleClaimGamification() {
      this.$bvModal.show('modal-gamification')
    },
    async getGamificationData() {
      const url = '/auth/api/v1/komcard/gamification'

      try {
        const res = await newAxiosIns.request({
          method: 'GET',
          url,
        })

        const { spending } = res.data.data
        this.spendingGamification = spending

        this.dataGamification = res.data.data
        this.isActiveGamification = true
      } catch (error) {
        this.isActiveGamification = false
      }
    },
    // END : GAMIFICATION

    // START : CAROUSEL AND PAGINATION
    async getAllDataCard() {
      const url = '/komcards/api/v1/card/list'

      const params = {
        offset: 0,
        // limit is 10000 this get all data
        limit: 10000,
      }

      try {
        const res = await newAxiosIns.get(url, { params })
        this.allDataCard = res.data.data

        const debitCard = [
          {
            value: '',
            name: 'Semua',
          },
          ...this.allDataCard.map(card => ({
            value: card.id,
            name: card.label,
          })),
        ]

        this.$nextTick(() => {
          this.optionsCardId = debitCard
        })
        return
      } catch (error) {
        this.$toast_error({
          message: 'Gagal load data card',
        })
      }
    },
    getNextDataCards(state) {
      let param
      if (this.firstRenderCards) {
        param = this.limit_card
      } else {
        param = 0
      }
      if (!this.lastDataCard) {
        let limit = this.limit_card
        let offset = this.offset_card + param

        if (state === 'last') {
          limit = this.totalCard
          offset = 0
        }

        this.loading.card = true
        const params = {
          search: this.search_card,
          offset,
          limit,
          status: this.status_card,
        }

        const url = '/komcards/api/v1/card/list'

        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data, meta } = res.data
            const newArrayData = data.map(obj => ({
              ...obj,
              balance: undefined,
            }))

            this.filtered_total = meta.filtered_total

            if (state === 'last') {
              this.dataCard = [...newArrayData]
            } else {
              this.dataCard = [...this.dataCard, ...newArrayData]
            }

            this.offset_card = data.length + this.offset_card + param

            if (data.length < this.limit_card) {
              this.lastDataCard = true
            }

            this.initialSlide = state === 'next' ? this.stateCarousel : this.filtered_total - 1
            this.firstRenderCards = false

            this.loading.card = false
          })
          .catch(e => {
            this.loading.card = false
            this.$toast_error({
              message: 'Gagal load data card',
            })
          })
      }
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      this.isActivePopover = false
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.getNextDataCards('next')
      }
    },

    nextCarousel() {
      this.$refs.carousel.next()
    },
    prevCarousel() {
      this.$refs.carousel.prev()
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    forceRerender() {
      this.carouselKey += 1
    },
    handleEnterSearch(e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.onSearch = true
        this.stateCarousel = 0
        this.initialSlide = 0
        this.offset_card = 0

        if (this.search_card && this.search_card !== '') {
          this.getListSearch()
          this.lastDataCard = true
        } else {
          this.lastDataCard = false
          this.getListCard()
        }
      } else {
        this.onSearch = false
      }
    },
    handleBeforeChange(e) {
      this.stateCarousel = e
    },

    // END : CAROUSEL AND PAGINATION

    resetSearch() {
      this.search_card = null
      this.lastDataCard = false
      this.initialSlide = 0
      this.getListCard()
    },

    changeView(view) {
      this.carouselKey = 0
      this.stateCarousel = 0
      this.$store.commit('komcards/UPDATE_VIEW_LIST', view)
      this.activeViewCard = view
    },
    async getListSearch() {
      this.loading.card = true

      const params = {
        search: this.search_card,
        offset: 0,
        limit: this.totalCard, // totalCard,
        status: this.status_card,
      }
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns
        .request({ url, method: 'get', params })
        .then(res => {
          const { data, meta } = res.data

          this.maxCard = meta.quota
          this.totalCard = meta.total
          this.filtered_total = meta.filtered_total

          const newArrayData = data.map(obj => ({ ...obj, balance: undefined }))

          this.maxCardMessage = `Batas maksimal Kartu Komcards yang bisa kamu buat adalah ${this.maxCard} kartu dan kamu telah mencapai batas maksimum untuk pembuatan Kartu Komcards.`
          this.$nextTick(() => {
            this.dataCard = newArrayData
          })

          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({
            message: 'Gagal memuat data kartu, silahkan coba lagi',
          })
        })
    },
    async checkUnlimitedCard() {
      // check unlimited card availibity
      const urlCheckUnlimited = '/komcards/api/v1/card/check-unlimited-quota'

      const availibity = await newAxiosIns.get(urlCheckUnlimited)

      this.isUnlimitedCard = availibity.data.data.status
    },
    async getListCard() {
      this.loading.card = true

      const params = {
        search: this.search_card,
        offset: this.offset_card,
        limit: this.limit_card,
        status: this.status_card,
      }
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns
        .request({ url, method: 'get', params })
        .then(res => {
          const { data, meta } = res.data

          this.maxCard = meta.quota
          this.totalCard = meta.total
          this.filtered_total = meta.filtered_total

          this.maxCardMessage = `Batas maksimal Kartu Komcards yang bisa kamu buat adalah ${this.maxCard} kartu dan kamu telah mencapai batas maksimum untuk pembuatan Kartu Komcards.`
          const newArrayData = data.map(obj => ({ ...obj, balance: undefined }))
          this.dataCard = newArrayData

          if (this.totalCard > this.minCard && this.totalCard < this.maxCard) {
            this.warningMessage = this.minCardMessage
          }
          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({
            message: 'Gagal memuat data kartu, silahkan coba lagi',
          })
        })
    },
    async getBalanceMonit(arrayOfIdCard) {
      this.loading.getBalanceMonit = true
      const url = '/komcards/api/v1/card/balance-cards'
      try {
        const res = await newAxiosIns.request({
          method: 'post',
          data: {
            card_ids: arrayOfIdCard,
          },
          url,
        })

        const { data } = res.data

        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < this.dataCard.length; j += 1) {
            if (data[i].id === this.dataCard[j].id) {
              this.dataCard[j].balance = data[i].balance
            }
          }
        }

        this.loading.getBalance = false
      } catch (error) {
        this.loading.getBalance = false
        this.$toast_error({
          message: 'Gagal load data get balance',
        })
      }
    },
    async getListMutation() {
      this.loading.mutation = true
      this.offset = 0
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type: this.transactionType,
        card_id: this.cardId,
        offset: this.offset,
        limit: this.limit,
      }
      const url = '/komcards/api/v1/card/mutation'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listMutation = data
          this.loading.mutation = false

          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading.mutation = false
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },

    async checkPin(event) {
      if (event === 'button-add') {
        this.loading.pin = true
      }

      if (!this.isUnlimitedCard && this.totalCard >= this.maxCard) {
        this.loading.pin = false
        this.$bvModal.show('modal-limit-quota')
        return
      }

      const url = '/v1/pin/check'
      await komshipAxiosIns
        .get(url)
        .then(res => {
          this.loading.pin = false
          const {
            data: { is_set },
          } = res.data

          // eslint-disable-next-line camelcase
          if (is_set === false) {
            this.$bvModal.show('modal-check-pin')
          } else {
            this.checkBalance()
          }
        })
        .catch(() => {
          this.loading.pin = false
          this.$toast_error({ message: 'Gagal check pin, silahkan coba lagi' })
        })
    },

    async balanceDetailForTransfer(id) {
      this.loading.balanceDetailForTransfer = true

      const url = `/komcards/api/v1/card/${id}/info-balance`

      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loading.balanceDetailForTransfer = false
          const balanceDetail = { ...data, balance: data.monit_balance }
          this.balanceDetail = balanceDetail
        })
        .catch(() => {
          this.loading.balanceDetailForTransfer = false
          this.$toast_error({
            message: 'Gagal memuat data balance, silahkan coba lagi',
          })
        })
    },
    async checkBalance() {
      this.loading.balance = true
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const {
            data: { balance },
          } = res.data
          this.balance = balance
          this.loading.balance = false
          if (balance < this.minSaldo && this.dataCard.length < 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minSaldo
          } else if (balance < this.minTopUp && this.dataCard.length >= 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minTopUp
          } else {
            this.$router.push({
              path: '/komcards/add',
              query: { count: this.totalCard },
            })
          }
        })
        .catch(() => {
          this.loading.balance = false
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },
    async getBalance() {
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns
        .get(url)
        .then(res => {
          const {
            data: { balance },
          } = res.data
          this.balanceData = balance
          if (this.showModalTopup === true) {
            this.$bvModal.show('modal-topup')
          }
        })
        .catch(() => {
          this.$toast_error({
            message: 'Gagal load data balance, silahkan coba lagi',
          })
        })
    },
    async getProfile() {
      const url = '/v1/my-profile'
      await komshipAxiosIns.post(url).then(res => {
        const { data } = res.data
        this.profile = data
      })
    },
    getNextDataMutation() {
      if (!this.lastData) {
        this.loading.mutation = true
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          transaction_type: this.transactionType,
          card_id: this.cardId,
          offset: this.offset,
          limit: this.limit,
        }
        const url = '/komcards/api/v1/card/mutation'
        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            this.loading.mutation = false
            this.$nextTick(() => {
              this.listMutation.push(...data)
            })
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading.mutation = false
            this.$toast_error({
              message: 'Gagal load data balance, silahkan coba lagi',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./Komcard.scss" />
